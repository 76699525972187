import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  browseCategory: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
  videosBrowseCategory: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
  starsCollectionBrowseCategory: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
  subBrowseCategory: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
  homeBanners: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
  },
};

export const homePageSlice = createSlice({
  name: "homePageData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setBrowseCategory: (state, action) => {
      let newResponse = action?.payload;
      newResponse.shift();
      const indexOfRecent = newResponse.findIndex(
        (data) => data.name === "Recently Added Images"
      );
      const element = newResponse.splice(indexOfRecent, 1)[0];
      newResponse.splice(0, 0, element);
      state.browseCategory.items = newResponse;
      state.browseCategory.error = {
        isError: false,
        message: "",
      };
    },
    setVideoBrowseCategory: (state, action) => {
      let newResponse = action?.payload;
      newResponse.shift();
      const indexOfRecent = newResponse.findIndex(
        (data) => data.display_name === "Recently Added Videos "
      );
      const element = newResponse.splice(indexOfRecent, 1)[0];
      newResponse.splice(0, 0, element);
      state.videosBrowseCategory.items = newResponse;
      state.videosBrowseCategory.error = {
        isError: false,
        message: "",
      };
    },
    setStarsCollectionBrowseCategory: (state, action) => {
      state.starsCollectionBrowseCategory.items = action?.payload;
      state.starsCollectionBrowseCategory.error = {
        isError: false,
        message: "",
      };
    },
    setBrowseCategoryError: (state, action) => {
      state.browseCategory.items = [];
      state.browseCategory.error = action.payload;
    },
    setVideoBrowseCategoryError: (state, action) => {
      state.videosBrowseCategory.items = [];
      state.videosBrowseCategory.error = action.payload;
    },
    setStarsCollectionBrowseCategoryError: (state, action) => {
      state.starsCollectionBrowseCategory.items = [];
      state.starsCollectionBrowseCategory.error = action.payload;
    },
    setSubBrowseCategory: (state, action) => {
      state.subBrowseCategory.items = action.payload;
      state.subBrowseCategory.error = {
        isError: false,
        message: "",
      };
    },
    setSubBrowseCategoryError: (state, action) => {
      state.subBrowseCategory.items = [];
      state.subBrowseCategory.error = action.payload;
    },
    setHomeBannersError: (state, action) => {
      state.homeBanners.items = [];
      state.homeBanners.error = action.payload;
    },
    sethomeBannersData: (state, action) => {
      state.homeBanners.items = action.payload;
      state.homeBanners.error = {
        isError: false,
        message: "",
      };
    },
   
  },
});

export default homePageSlice.reducer;

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setBrowseCategory,
  setBrowseCategoryError,
  setSubBrowseCategory,
  setVideoBrowseCategory,
  setVideoBrowseCategoryError,
  setSubBrowseCategoryError,
  setStarsCollectionBrowseCategory,
  setStarsCollectionBrowseCategoryError,
  sethomeBannersData,
  setHomeBannersError
} = homePageSlice.actions;

export const homePageDataSelector = (state) => state.homePageData;
